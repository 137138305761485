import { ReactComponent as TynybayLogo } from "../assests/TynybayLogo.svg";
import { ReactComponent as ItcvegsLogo } from "../assests/ItcvegsLogo.svg";

import { ReactComponent as Pdfdownload } from "../assests/Pdfdownload.svg";
import img2 from "../assests/img2.jpg";
import imag1 from "../assests/imag1.jpg";
import img3 from "../assests/img3.jpg";
import img4 from "../assests/img4.jpg";
import img5 from "../assests/img5.jpg";
import img6 from "../assests/img6.jpg";
import {ReactComponent as MicrosoftLogo} from "../assests/microsoftlogo.svg"

import "./LandingPage.css";

const resources = [
  {
    title: "AI for Underwriting and Risk Management",
    description:
      "Explore AI's impact on smarter underwriting and risk management.",
    image: imag1,
    pdf: "https://tynybay-demo-bucket.nyc3.digitaloceanspaces.com/AI%20for%20Underwriting%20and%20Risk%20Management.pdf",
  },
  {
    title: "AI for Marketing & Sales",
    description:
      "Discover how AI enhances marketing and sales through data-driven insights and personalized strategies.",
    image: img2,
    pdf: "https://tynybay-demo-bucket.nyc3.digitaloceanspaces.com/AI%20for%20Claims%20Management.pdf",
  },
  {
    title: "AI for Claims Management",
    description:
      "Learn how AI streamlines claims management with faster processing and improved accuracy.",
    image: img3,
    pdf: "https://tynybay-demo-bucket.nyc3.digitaloceanspaces.com/AI%20for%20Claims%20Management.pdf",
  },
  {
    title: "AI for Customer Service & Experience",
    description: "Explore how AI enhances customer service and experience.",
    image: img4,
    pdf: "https://tynybay-demo-bucket.nyc3.digitaloceanspaces.com/AI%20for%20customer%20Service%20&%20Experience.pdf",
  },
  {
    title:"Innovate with GenAI",
    description:"Explore generative AI's potential for innovation and creativity.",
    image:img5,
    pdf:"https://tynybay-demo-bucket.nyc3.digitaloceanspaces.com/Innovate%20with%20GenAI.pdf"
  },
  {
    title:"Build unique GenAI experiences that scale",
    description:"Create scalable, unique experiences powered by generative AI.",
    image:img6,
    pdf:"https://tynybay-demo-bucket.nyc3.digitaloceanspaces.com/Build%20unique%20GenAI%20experiences%20that%20scale%20Azure%20AI%20Platform.pdf"
  }
];

const handledownloadAll = () => {
  resources.forEach((resource) => {
      handleDownload(resource.pdf, resource.title);
  });
};


const handleDownload = async (pdfUrl, fileName) => {
  console.log("Downloading:", pdfUrl);
  try {
  
    const response = await fetch(pdfUrl);
    console.log("Response Status:", response.status);

    if (!response.ok) throw new Error("Network response was not ok");

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    link.download = fileName || pdfUrl.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Download failed:", error);
  }
};

const LandingPage = () => {
  return (
    <div className="Main-container">
      <div>
        <div className="header">
          <div className="heading-tynyo">
            <a href="https://www.tynybay.com/">
              <TynybayLogo />
            </a>
          </div>
          <div>
            <MicrosoftLogo/>
          </div>
          <div className="itcvegas-heading">
            <ItcvegsLogo />
          </div>
        </div>
      </div>

      <div className="scrollable-content">
        <div className="background-image">
          <div>
            <h1 className="background-text">
              Unlock the Future of AI with TYNYBAY and Azure AI
            </h1>
            <button
              className="free-button"
              onClick={() =>
                (window.location.href =
                  "https://outlook.office.com/bookwithme/user/6d6dd6edcd844c0a97404c6e49edc651@tynybay.com/meetingtype/OzE_37x86UiHUWx_ZaAqdA2?anonymous&ep=mlink")
              }
            >
              Get free AI consultation
            </button>
          </div>
        </div>

        <h1 className="cards-headings">Exclusive AI Resources</h1>
        <div className="downloaddiv">
        <button className="downloadall" onClick={()=>handledownloadAll()}>
                       Download All
                </button>
        </div>
        <div className="cards-container">
          <div className="resources-cards">
            {resources.map((resource, index) => (
              <div key={index} className="resource-card">
                <div className="resource-image">
                  <img src={resource.image} alt="back" className="image" />
                </div>
                <div className="resource-info">
                  <h3 className="card-title">{resource.title}</h3>
                  <p>{resource.description}</p>
                </div>
                <div
                  className="download-button"
                  onClick={() =>
                    handleDownload(resource.pdf, `${resource.title}.pdf`)
                  }
                >
                  <Pdfdownload />
                </div>
              </div>
            ))}
          </div>
        </div>

        <footer className="fixed-footer">
          {/* <button className="newsletter-button">Subscribe to our AI newsletter</button> */}
          <p className="footer-description">
            At TYNYBAY, we are committed to building AI that’s ethical, secure,
            and transparent. Every Co-Pilot adheres to Microsoft’s Responsible
            AI standards, ensuring data protection and compliance.
          </p>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
